<template>
  <div class="details padding20">
    <!-- <el-row class="breadcrumb" align="middle" type="flex">
    </el-row> -->
    <div class="details-title">
        <div class="datail-bottom">
            <span class="title-header">{{shareInfo.name}}</span>
        </div>
        <div class="title-text">
          <ul class="text-name">
             <li class="name-name"><span>共享时间:</span><span>{{shareInfo.createDate|date}}</span></li>
             <li class="name-title"><span>共享人:</span>{{shareInfo.ownerName}}<span></span></li>
          </ul>
          <div class="datil-btus" v-if="shareInfo.state!==1&&shareInfo.state!==2">
              <el-button type="primary" plain @click="editCommit">修改提交</el-button>
              <el-button type="primary" plain @click="cancleCommit">取消</el-button>
            </div>
      </div>
    </div>
    <!-- 文件资源 -->
    <ShowFileList :shareInfo="shareInfo"  :permissions="permissions" :show="true" @addFiles="addFile" />
    <!-- 共享目标 -->
    <AddTarget :shareInfo="shareInfo"  :permissions="permissions"  :show="true" />
    <div class="details-power details-read">
      <el-row type="flex" justify="space-between" class="title-box">
        <el-col :span="3"
          ><div class="read-title"><span></span>操作授权</div></el-col
        >
      </el-row>
      <el-form ref="form" label-width="80px">
        <el-form-item label="生效日期: ">
            <el-date-picker
            v-model="query.expireStartTime"
            type="datetime"
            :picker-options="expireTimeOption"
            @change="onChange('','',true)"
            value-format='yyyy-MM-dd HH:mm:ss'
            placeholder="选择日期时间">
          </el-date-picker> - 
          <el-date-picker
            v-model="query.expireEndTime"
            type="datetime"
            :picker-options="expireTimeOption"
            @change="onChange('','',true)"
            value-format='yyyy-MM-dd HH:mm:ss'
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="可共享: ">
          <el-switch  @change="onChange($event,'share')" v-model="permissions.share" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
        </el-form-item>
        <el-form-item label="不可复制: ">
          <el-switch @change="onChange($event,'copy')" v-model="permissions.copy" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
        </el-form-item>
        <el-form-item label="可打印: ">
          <el-switch @change="onChange($event,'print')" v-model="permissions.print" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
        </el-form-item>
        <el-form-item label="可下载: ">
          <el-switch @change="onChange($event,'download')" v-model="permissions.download" :active-value="1" :inactive-value="0" :disabled="shareInfo.state==1||shareInfo.state==2"></el-switch>
        </el-form-item>
        <el-form-item label="添加水印: ">
          <span>默认水印</span>
        </el-form-item>
         <el-form-item label="数据脱敏" >
          <el-button @click="doSetEnvelop" v-if='docInfo != null' >设置</el-button>
          <setEnvelop  ref="setEnvelop" vkey='edit'  :docInfo='docInfo' @handleCheckedChange='commitEnvelops' @doPermissionSet='doPermissionSet'></setEnvelop>
        </el-form-item>
      </el-form>
    </div>
    <div class="details-range details-read" v-if="showRead">
      <el-row type="flex" justify="space-between" class="title-box">
        <el-col :span="3"
          ><div class="read-title"><span></span>阅读范围</div></el-col
        >
      </el-row>
      <el-collapse v-model="activeNames" @change="handleChange" accordion>
        <el-collapse-item name="1">
          <template slot="title">
            <el-row type="flex" justify="space-between" class="document-title">
              <el-col :span="18"
                >{{this.shareInfo.resources.length == 1&& this.shareInfo.resources[0].type!=0 ? this.shareInfo.resources[0].name : ''}}</el-col
              >
              <el-col :span="8" class="el-readios">
                <div class="document-title-right">
                  <el-radio-group
                    v-model="radioSelect"
                    class="document-title-right-radiu"
                    @change="radioChangeEvent"
                  >
                    <el-radio :label="1">全部页面</el-radio
                    ><el-radio :label="2">部分页面</el-radio>
                  </el-radio-group>

                  <!-- <el-input @change="editChecked($event)" v-model="page" style="width: auto"></el-input> -->
                </div>
              </el-col>
            </el-row>
          </template>
          <div class="container">
            <el-card :class="{'document-select document':item.selected }"
              v-for="(item,i) in showReadList"
              :key="i"
              :body-style="{ padding: '0px' }"
              @click.native="onPageSelectEvent(item)"
            >
              <div class="document-top">
                <img :src="item.path|thumbnailUrl"
                  :alt="item.path | thumbnailUrl"
                />
              </div>
              <div class="document-middle">{{ item.pageIndex + 1 + '页'}}</div>
              <img src="@/assets/select.png" alt="" class="select" v-if="item.selected"/>
            </el-card>
          </div>
          <div class="pagination">
            <el-pagination
            background
            @current-change="pageChanged"
            layout="prev, pager, next"
            :page-size="pages.pageSize"
            :total="pages.total">
          </el-pagination>
         </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div v-show='regionDes' class="modelArea" >
      <div>划区域遮掩 <i class="el-icon-close closeBtn" @click="closeModel"></i></div>
       <iframe name="child" id='child' scrolling=no :src="iframeSrc" frameborder="0" width="1000" height="570"></iframe>
    </div>
  
  </div>
</template>
<script>
import { requestAPI, shareAPI, qrReadAPI, documentAPI} from 'liankong-ui-api' 
import AddSharePeople from './AddSharePeople.vue'
import AddShareFile from './AddShareFile.vue'
import AddTarget from '@/components/AddTarget.vue'
import ShowFileList from '@/components/ShowFileList.vue'
 import setEnvelop from '@/packages/FileCart/src/SetEnvelop.vue'
import { log } from 'util';
import { DH_CHECK_P_NOT_SAFE_PRIME } from 'constants'
export default {
  data() {
    return {
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      activeNames:'1', 
      page: '', //
      permissions:{
      }, // 权限
      showRead:false,
      shareInfo:{},
      docList:[], // 
      radioSelect: '',  // 全部页面和部分页面
      selectCount: 0, // 选择的个数
      pages:{
        pageSize:20,
        total:0
      },
      query:{
        expireStartTime:null,
        expireEndTime:null,
        pageNum: 1,
        pageSize: 20
      },
      showReadList:[], // 页面回显
      verker:'shareDocList',
      envelopList:[],
      envelopCheck:[],
      list:[],
      shareId:'',
      isEnvelop:false,
      regionDes:false,
      iframeSrc:null
    };
  },
  components: {
    AddSharePeople,
    AddShareFile,
    AddTarget,
    ShowFileList,
    setEnvelop
  },
  computed: {
    docInfo() {
      const resources = this.$store.state.auth.editShare.resources || []
      if(resources.length == 1){
        //如果是文件，则可以选择，就算只有一个且是文件夹，也不行
        const doc = resources[0];
        doc.id = doc.documentId;
        return doc.type === 1 ?  doc: null
      }
      return null       
    },
  },
  methods: {
    doPermissionSet(key,value){
      this.onChange(value,key)
    },
    // 设置数据脱敏
    doSetEnvelop(){
      this.$refs['setEnvelop'].dialogVisible = true;
      // this.$refs['setEnvelop'].getIframeUrl();
      this.$refs['setEnvelop'].getEnvelopField();
      this.$refs['setEnvelop'].envelops = this.shareInfo.envelops;

      if(this.permissions.shadeKeyword){
        this.$refs['setEnvelop'].keyWordList = this.permissions.shadeKeyword.split(',');
      }
    },
    closeModel(){
      this.regionDes = !this.regionDes;
    },
    pageChanged(num){
      this.query.pageNum = num
      this.showDoument()
    },
    addFile({state,resource}){
      // this.envelopList=state?[]:this.envelopList
      this.isEnvelop = resource.length==1?true:false
     state ? this.showDoument() : this.showDoument('添加')
    },
    // 取消
    cancleCommit(){
      // this.$router.replace({path:'/shares/shares'})
      this.$router.replace({path:'/sharetion/sharedoc'})
    },
    // 提交修改
    editCommit(){
      const selectPageNumList = this.docList.filter(p => p.selected).map(p => p.pageIndex)
      if(this.radioSelect == 1 || this.selectCount === 0){
        this.onChange(0,'readPageType',false,'修改')
        this.onChange(null,'readPage',false,'修改',false)
      } else {
        const selectPageNumList = this.docList.filter(p => p.selected).map(p => p.pageIndex)
        this.onChange(1,'readPageType',false,'修改')
        this.onChange(selectPageNumList.join(','),'readPage',false,'修改',false)
      }
    },
    //提交脱敏信息
    commitEnvelops(list){
      shareAPI.resetEnvelop(this.shareId,list)
      .then(res=>{
        if(res.code==0){
          this.$message.success('变更成功！')
        }else{
          this.$message.error('变更失败！')
        }
      }).catch(message => {
        this.$message.error('变更失败！')
      })
    },
    handleChange(){
      this.activeNames = '1'
    },
    // 变更状态
    onChange($event,val,state=false,type,msg=true){
        let stateVal = {}
        let authUpdate = ''
        if (state) {
        authUpdate = 'share/update'
        if(!this.$options.filters['dateRengeCompare'](this.query['expireStartTime'], this.query['expireEndTime'])){
          this.query.expireStartTime=this.shareInfo.expireStartTime
          this.query.expireEndTime=this.shareInfo.expireEndTime
          this.$message.warning("结束时间不应在于开始时间")
          return
        }
        this.loadingShow('变更中')
        let  {expireStartTime,expireEndTime} = this.query
        this.shareInfo.expireStartTime = expireStartTime!==null ? expireStartTime : ''
        this.shareInfo.expireEndTime = expireEndTime!==null ? expireEndTime:''
          stateVal = {
             id:this.shareInfo.id,
             expireStartTime: this.shareInfo.expireStartTime,
             expireEndTime: this.shareInfo.expireEndTime,
          }
        } else {
          if($event==null){
            authUpdate = 'auth/removeSharePermission';
            
          }else{
            authUpdate = 'auth/updateOrAddPermission'
          }
           
          stateVal = {
            shareId:this.shareInfo.id,
            permission: {
              name: val,
              value:$event
            }
          }
        }
       this.$store.dispatch(authUpdate,stateVal ).then(state => {
          this.loadingHide()
          if (!state) {
            this.permissions[val] = $event
          } else if(type=="修改") {
            this.cancleCommit()
          }
          if(msg){
            this.$message.success('变更成功')
          }
        }).catch(err => {
           this.loadingHide()
           this.$message.error('变更失败')
        })
    },
    onload(){
      let {id} = this.$route.query
      this.$store.dispatch("auth/loadShare",id).then(data => {
        this.shareInfo = this.$store.state.auth.editShare
        
        this.query.expireStartTime = this.shareInfo.expireStartTime
        this.query.expireEndTime = this.shareInfo.expireEndTime
        this.permissions = this.shareInfo.permissions ? this.shareInfo.permissions.reduce((map, p) => {
            map[p.name] = p.value
            return map
          }, {}) : null
        this.$store.commit('doc/createViewer', {
          viewerKey: this.verker,
          defaultFiles: this.shareInfo.resources
        })
          this.showDoument()
          this.shareId =this.shareInfo.resources[0].shareId;
          let documentId = this.shareInfo.resources[0].documentId;

      })
    },
    showDoument(){
      if (this.shareInfo.resources.length == 1) {
        let {resources} = this.shareInfo
        if(resources[0].type!=0){
          let docId = resources[0].documentId || resources[0].id
          this.documentList(docId)
        }
      } else {
        this.showRead = false
      }
    },
    documentList(docId){
      let {pageNum,pageSize} = this.query
      documentAPI.thumbnail(docId,pageSize,pageNum).then(({code, data, message}) => {
        if(code == 0){
          this.showRead = true 
          const { pages, records, total, current } = data
         this.pages.total = total
         if(this.radioSelect==1 || this.permissions.readPageType==0){
            // 选中全部页面
            this.showReadList = records.map(page=>{
              page.selected = true
              return page
            })
            // 第一次加载
            if(this.docList.length==0){
              this.docList = [...this.showReadList]
              this.radioSelect = this.selectCount == this.pages.total || this.permissions.readPageType==0 ? 1 : 2
            } else {
              this.selectCount = 0
              records.forEach((items,i)=>{
                const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                if(Index==-1){
                  items.selected = true
                  this.docList.push(items)
                }
              })
              records.forEach(item=>(
                this.docList.forEach(ele=>{
                  if(item.path==ele.path){
                    item.selected = ele.selected
                  }
                })
              ))
              this.showReadList = [...records]
              this.docList.forEach(item=>{
                if(item.selected){
                  this.selectCount++
                }
              })
              this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            }
            
          } else if(this.permissions.readPageType==0){
            // 第一次加载
            if(this.docList.length==0){
              this.showReadList = records.map(page=>{
                page.selected = true
                return page
              })
              this.docList = [...this.showReadList]
            } else {
              this.selectCount = 0
              records.forEach((items,i)=>{
                const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                if(Index==-1){
                  items.selected = true
                  this.docList.push(items)
                }
              })
              records.forEach(item=>(
                this.docList.forEach(ele=>{
                  if(item.path==ele.path){
                    item.selected = ele.selected
                  }
                })
              ))
              this.showReadList = [...records]
              this.docList.forEach(item=>{
                if(item.selected){
                  this.selectCount++
                }
              })
              this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            }
          }
          else{
            const readPage = this.permissions.readPage
            //数据打标
            const readPageList =typeof(readPage)=='number'?readPage:( readPage ? readPage.split(',') : null)
            if(this.docList.length==0){
              this.showReadList = records.map(page => {
                page.selected = readPageList ? readPageList.indexOf("" + page.pageIndex) > -1 : false
                return page
              })
              this.docList = [...this.showReadList]
              this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            } else {
              this.selectCount = 0
              records.forEach((items,i)=>{
                const Index = this.docList.findIndex((item) => {return item.path == records[i].path})
                if(Index==-1){
                  items.selected = readPageList ? readPageList.indexOf("" + items.pageIndex) > -1 : false
                  this.docList.push(items)
                }
              })
              records.forEach(item=>(
                this.docList.forEach(ele=>{
                  if(item.path==ele.path){
                    item.selected = ele.selected
                  }
                })
              ))
              this.showReadList = records
              this.docList.forEach(item=>{
                if(item.selected){
                  this.selectCount++
                }
              })
              this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
            }

          }
        }else{
          this.showRead = false
          this.$message.error(message)
        }
      })
    },
    onPageSelectEvent(item){
      // item.selected = !item.selected
      this.showReadList.forEach(items=>{
        if(items.path==item.path){
          items.selected = !items.selected
        }
      })
      // this.$set(item,'selected',!item.selected)
      this.$nextTick(() => this.$forceUpdate())
      this.docList.forEach(items=>{
        if(items.path==item.path){
          items.selected = items.selected 
        }
      })
      if (item.selected) {
        this.selectCount++;
        this.radioSelect =  this.selectCount == this.pages.total ? 1 : 2
      } else {
        this.selectCount--;
        this.radioSelect = this.selectCount == this.pages.total ? 1 : 2
      }
    },
    radioChangeEvent(value){
      if(value == 1) { // 点击全部页面
        this.selectCount = this.page.total;
        this.docList.forEach(listItem=>{
          this.$set(listItem,'selected',true)
        })
        this.showReadList.forEach(item=>{
          this.$set(item,'selected',true)
        })
      }
    },

  },
  created() {
  },
  mounted(){
    this.onload()
    
 }
};
</script>
<style lang="less" scoped>
@import "../../styles/index";
.modelArea{
  position: fixed;
  top: 15vh;
  height: 640px;
  width: 1000px;
  left: 50%;
  margin-left: -500px;
  z-index: 999;
  border-radius: 6px;
  overflow: hidden;
}
.modelArea div{
  position: relative;
  background: #fff;
  line-height: 24px;
  color: #303133;
  font-size: 28px;
  box-sizing: border-box;
  padding: 20px 30px;
}
.closeBtn{
  position: absolute;
  top: 20px;
  right: 20px;
}
.details {
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  .details-title {
    width: 100%;
    min-height: 130px;
    background: #f9fcff;
    border: 1px solid #e7eef4;
    padding: 20px;
    flex-flow: column;
    box-sizing: border-box;
    margin-top: 20px;
    .title-header {
      font-size: 29px;
      margin: 0;
      color: #000;
    }
    .title-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 0 20px;
        box-sizing: border-box;
        li {
          padding: 5px 0;
        }
      }
      .text-name {
        display: flex;
        margin-top: 15px;
        .name-name {
          // width: 200px;
        }
        .name-title {
          margin-left: 20px;
        }
      }
      .text-apply {
        .apply-context {
          display: flex;
          .context-reason {
            width: 100px;
          }
          .context-text {
            flex: 1;
            word-break:break-all;
          }
        }
      }
      .datil-btus {
        .el-button.el-button--primary.is-plain {
            background: #fff;
        }
      }
    }
  }
  .details-title:hover {
    box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
    .datil-btus {
      .el-button.el-button--primary.is-plain {
          background: #50a2ef;
          color: #fff;
      }
    }
  }
  .details-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px solid #f0f1f5;
      padding-bottom: 16px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      .el-table {
       /deep/ .el-table__body-wrapper {
         .el-table__body {
          colgroup{
            display:flex;
          }
          tbody {
            display: flex;
            flex-wrap: wrap;
            .el-table__row {
              width: 50%;
              td {
                border:0;
              }
            }
          }
        }
       }
      }
      .title-link {
        white-space: nowrap;
        position: relative;
        font-size: @h3;
        cursor: pointer;
      }
    }
    /deep/ .el-form-item {
     margin-bottom:5px;
    }
  }

  .target-context {
    .target-item {
      margin-top: 10px;
      .left {
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 5px;
        font-size: 20px;
        color: #fff;
        font-weight: 400;
        margin-right: 22px;
      }
      .center {
        margin-right: 50px;
        div:nth-child(1) {
          font-size: @h3;
        }
        div:nth-child(2) {
          font-size: @h6;
          color: #b6b6b6;
        }
      }
    }
  }
}
.document-title {
  width: 100%;
  .el-readios {
    display: flex;
  }
  .document-title-right {
    display: flex;
    align-items: center;
    padding-right: 10px;
    .document-title-right-radiu {
      margin-right: 10px;
      display: flex;
    }
  }
}
.document-middle{
  text-align: center;
}
.breadcrumb {
  font-size: @h3;
  padding: 15px 0px;
}
.details-power /deep/ .el-form-item__content {
  border-bottom: 1px dashed #dadada;
}
/deep/ .details-power .el-form {
  margin: 10px 0;
  .el-form-item {
    margin-bottom: 12px;
    .el-form-item__content {
      padding-left: 2px;
      .el-date-editor {
        margin-bottom: 3px;
      }
    }
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  padding: 16px;
  background: #f0f1f4;
}
.document {
  text-align: center;
  box-sizing: border-box;
  
  position: relative;
}
.document-select{
border: 1px solid @Brand;
}
.document img.select {
  position: absolute;
  right: 0;
  bottom: 0px;
  font-size: 20px;
}
.document-top img {
  width: 100%;
  height: auto;
}
.pagination {
  margin-top: 20px;
}
</style>